.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainPad10 {
  padding-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.root {
  text-align: center;
}
.rootColor {
  text-align: center;
  background-color: #391265;
}

.rootColorBg {
  text-align: center;
  background-color: #5bef5b;
}

.closebtnleft {
  float: right;
}
.closebtnRight {
  float: left;
}
.footer {
  float: inline-end;
}

.slider {
  width: 100%;
  overflow: hidden;
  background-color: #fef5ff;
  padding-bottom: 40px;
}
.slider .sliderRow {
  width: 4968px;
  height: 216px;
  background-size: 4968px 216px;
  animation: slide 80s linear infinite;
  background-image: url("../assets/avatars/smp.png");
}

@keyframes slide {
  0% {
    transform: translate3d(-3000px, 0, 0);
  }
  100% {
    transform: translate3d(1000px, 0, 0);
  }
}

@media screen and (min-width: 992px) {
  .navOne {
    float: "left";
  }

  .navTwo {
    float: "right";
    margin-left: auto;
  }
}

@media screen and (max-width: 600px) {
  .mobile {
    display: flex;
  }
}
