@import url("https://fonts.googleapis.com/css2?family=Inconsolata&display=swap");
html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inconsolata" !important;
  font-size: large !important;
  background-color: #391265 !important;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

footer {
  float: inline-end;
  font-weight: bold;
}

.slider .sliderRow {
  width: auto;
  height: 216px;
  /* background-size: auto; */
  animation: slide 80s linear infinite;
}

@keyframes slide {
  0% {
    transform: translate3d(-3000px, 0, 0);
  }
  100% {
    transform: translate3d(1000px, 0, 0);
  }
}

.main {
  padding: 5rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainPad10 {
  padding-top: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.root {
  text-align: center;
}
.rootColor {
  text-align: center;
  background-color: #391265;
}

.rootColorBg {
  text-align: center;
  background-color: #5bef5b;
}

.closebtnleft {
  float: right;
}
.closebtnRight {
  float: left;
}
.footer {
  float: inline-end;
}

footer, .footlink{
  width:-webkit-fill-available
}

@media screen and (min-width: 992px) {
  .navOne {
    float: "left";
  }

  .navTwo {
    float: "right";
    margin-left: auto;
  }
}

@media screen and (max-width: 900px) {
  .mobile {
    display: flex;
  }
}
