html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inconsolata" !important;
  font-size: large !important;
  background-color: #391265;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

footer {
  float: inline-end;
  
}

