@import url(https://fonts.googleapis.com/css2?family=Inconsolata&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inconsolata" !important;
  font-size: large !important;
  background-color: #391265;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

footer {
  float: inline-end;
  
}


html,
body {
  padding: 0;
  margin: 0;
  font-family: "Inconsolata" !important;
  font-size: large !important;
  background-color: #391265 !important;
  text-align: center;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

footer {
  float: inline-end;
  font-weight: bold;
}

.slider .sliderRow {
  width: auto;
  height: 216px;
  /* background-size: auto; */
  -webkit-animation: slide 80s linear infinite;
          animation: slide 80s linear infinite;
}

@-webkit-keyframes slide {
  0% {
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(1000px, 0, 0);
            transform: translate3d(1000px, 0, 0);
  }
}

@keyframes slide {
  0% {
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(1000px, 0, 0);
            transform: translate3d(1000px, 0, 0);
  }
}

.main {
  padding: 5rem 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.mainPad10 {
  padding-top: 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.root {
  text-align: center;
}
.rootColor {
  text-align: center;
  background-color: #391265;
}

.rootColorBg {
  text-align: center;
  background-color: #5bef5b;
}

.closebtnleft {
  float: right;
}
.closebtnRight {
  float: left;
}
.footer {
  float: inline-end;
}

footer, .footlink{
  width:-webkit-fill-available
}

@media screen and (min-width: 992px) {
  .navOne {
    float: "left";
  }

  .navTwo {
    float: "right";
    margin-left: auto;
  }
}

@media screen and (max-width: 900px) {
  .mobile {
    display: flex;
  }
}

.common_main__pdEVA {
  padding: 5rem 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.common_mainPad10__3Q0cv {
  padding-top: 10px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.common_root__drUiu {
  text-align: center;
}
.common_rootColor__3MUPa {
  text-align: center;
  background-color: #391265;
}

.common_rootColorBg__3HsP3 {
  text-align: center;
  background-color: #5bef5b;
}

.common_closebtnleft__1BjQi {
  float: right;
}
.common_closebtnRight__3_bvk {
  float: left;
}
.common_footer__21zdu {
  float: inline-end;
}

.common_slider__2J3uj {
  width: 100%;
  overflow: hidden;
  background-color: #fef5ff;
  padding-bottom: 40px;
}
.common_slider__2J3uj .common_sliderRow__1vLMp {
  width: 4968px;
  height: 216px;
  background-size: 4968px 216px;
  -webkit-animation: common_slide__2Uj2V 80s linear infinite;
          animation: common_slide__2Uj2V 80s linear infinite;
  background-image: url(/static/media/smp.e337ba66.png);
}

@-webkit-keyframes common_slide__2Uj2V {
  0% {
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(1000px, 0, 0);
            transform: translate3d(1000px, 0, 0);
  }
}

@keyframes common_slide__2Uj2V {
  0% {
    -webkit-transform: translate3d(-3000px, 0, 0);
            transform: translate3d(-3000px, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(1000px, 0, 0);
            transform: translate3d(1000px, 0, 0);
  }
}

@media screen and (min-width: 992px) {
  .common_navOne__1ggv0 {
    float: "left";
  }

  .common_navTwo__dElH7 {
    float: "right";
    margin-left: auto;
  }
}

@media screen and (max-width: 600px) {
  .common_mobile__2Ixkk {
    display: flex;
  }
}


.landing {
  background: #000;
}

.header {
  /* max-height: 100vh;
  width: 100vw;
  display: flex; */
  align-items: center;
  justify-content: center;
  /* background-image: url('../../assets/header_image.jpg'); */
  /* background-repeat: no-repeat;
  background-size: cover; */
}

.header-title {
  width: 100%;
  height: auto;
}

.section {
  /* display: flex;
  /* flex-direction: column; */
  /* justify-items: center;
  justify-content: center; */
  color: #fff;
}
.section-title {
  text-align: center;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: auto;
  font-weight: bold;
  /* line-height: 3.25rem; */
  text-shadow: 1px 1px rgb(34 230 202);
  padding: 10px;
  font-size: larger;
}

.section-text {
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 60%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.cards {
  padding:20px;
}

.card {
  text-align: center;
  padding: 3rem 2rem;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  box-shadow: 0px 0px 10px 5px rgb(34 230 202);
  background-color: #000 !important;
}

.card:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.landing a{
  text-decoration: unset;
  color: #fff;
}

.footer {
  color: #fff;
  text-align: center;
  padding: 2rem;
}

.card-text {
  padding-top:20px;
}

.icons {
  display: flex;
  justify-content: center;
  grid-gap: 1.5rem;
  gap: 1.5rem;
  margin-bottom: 1rem;
}

.icon {
  font-size: 30px;
}

@media (min-width: 768px) {
  .section-title {
    display:none;
  }
  }

